import { request } from 'network/request'

export function postInvoiceData(parameter) {
  return request({
    url: '/invoice',
    method: 'post',
    data: parameter
  })
}

export function putInvoiceData(parameter) {
  return request({
    url: '/invoice',
    method: 'put',
    data: parameter
  })
}

export function getInvoiceDatas(parameter) {
  return request({
    url: '/invoice/' + parameter.user_name,
    method: 'get',
    data: parameter
  })
}

export function getInvoiceDataAll(parameter) {
  return request({
    url: '/invoice/all/' + parameter.project_code,
    method: 'get',
    data: parameter
  })
}

export function getInvoiceDataOne(parameter) {
  return request({
    url: '/invoice/one/' + parameter.invoice_code,
    method: 'get',
    data: parameter
  })
}

export function postInvoiceDetailsData(parameter) {
  return request({
    url: '/invoice/detail',
    method: 'post',
    data: parameter
  })
}

export function putInvoiceDetailsData(parameter) {
  return request({
    url: '/invoice/detail',
    method: 'put',
    data: parameter
  })
}

export function getInvoiceDetailsDataAll(parameter) {
  return request({
    url: '/invoice/detail/all/' + parameter.invoice_code,
    method: 'get',
    data: parameter
  })
}

export function deleteInvoiceDetail(parameter) {
  return request({
    url: '/invoice/detail/' + parameter.invoice_details_code,
    method: 'delete',
    data: parameter
  })
}

export function getInvoicePdf(parameter) {
  return request({
    url: '/invoice/pdf/' + parameter.invoice_code,
    method: 'get',
    data: parameter
  })
}